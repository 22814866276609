import { randomFromArray } from "./global/random";
import data from "../data/data-v2.json";
import { getParameterByName } from "./global/util";

const apiServer =
  window.location.hostname === "localhost" &&
  window.location.href.includes("env=dev")
    ? "http://localhost:8000"
    : "https://sos.opl.io";

let decade = getParameterByName("decade");
console.log("decade", decade);

let appElem = document.getElementById("app");
let messageElem = document.getElementById("message");
let buttonElem = document.getElementById("randomize");
let tweetButtonElem = document.getElementById("tweet");

// Add Google Analytics event to tweet button click
tweetButtonElem.addEventListener("click", () => {
  gtag("event", "Click", {
    event_category: "Tweet",
    event_label: "Favorite Idea",
  });
});

let company = [];
let adjective = [];
let thing = [];

const apiFallback = err => {
  console.log(err);
  for (let index = 0; index < data.company.length; index++) {
    company.push({ content: data.company[index] });
  }
  for (let index = 0; index < data.adjective.length; index++) {
    adjective.push({ content: data.adjective[index] });
  }
  for (let index = 0; index < data.things.length; index++) {
    thing.push({ content: data.things[index] });
  }
  randomizePhrase();
  throw err;
};

const generatePhrase = (data, options) => {
  let randCompany = randomFromArray(company);
  let randAdjective = randomFromArray(adjective);
  let randThing = randomFromArray(thing);
  const chanceOfAdjective = randThing.chanceOfAdjective
    ? parseInt(randThing.chanceOfAdjective) * 0.01
    : 0.5;
  console.log(chanceOfAdjective);

  let message = {};
  if (false && Math.random() < chanceOfAdjective) {
    message.content = `The ${randCompany.name} of ${randAdjective.name} ${randThing.name}`;
  } else {
    message.content = `The ${randCompany.name} of ${randThing.name}`;
  }
  return message;
};

const typingDelete = (element, callback) => {
  var content = element.textContent;
  if (content.length > 0) {
    element.textContent = content.substring(0, content.length - 1);
    setTimeout(() => {
      typingDelete(element, callback);
    }, 20);
  } else {
    callback();
  }
};

const typing = (element, text, callback) => {
  var content = element.textContent;
  if (content.length < text.length) {
    element.textContent = text.substring(0, content.length + 1);
    setTimeout(() => {
      typing(element, text, callback);
    }, 50 + (Math.floor(Math.random() * 80) - 40));
  } else {
    callback();
  }
};

let randomizing = false;
let animating = false;
let animationTimeout = 0;
const randomizePhrase = () => {
  if (!randomizing && !animating) {
    randomizing = true;
    animating = true;
    buttonElem.classList.add("disabled");

    document.body.classList.add("working");
    animationTimeout = setTimeout(() => {
      document.body.classList.remove("working");
      animating = false;
      checkCompletion();
    }, 3500);

    typingDelete(messageElem, () => {
      let message = generatePhrase(data);
      const base = `https://twitter.com/intent/tweet`;
      const text = `I have a new business idea: ${message.content} via The Something of Something #changingthegame`;
      const url = "https://thesomethingofsomething.com/";
      tweetButtonElem.href = `${base}?text=${encodeURIComponent(
        text
      )}&url=${encodeURIComponent(url)}`;

      setTimeout(() => {
        typing(messageElem, message.content, () => {
          randomizing = false;
          checkCompletion();
        });
      }, 1000);
    });
  }
};
const checkCompletion = () => {
  if (!randomizing && !animating) {
    buttonElem.classList.remove("disabled");
  }
};
const randomizePhraseBuffer = () => {
  randomizePhrase();

  gtag("event", "Click", {
    event_category: "Next Idea",
    event_label: "Next Idea",
  });
};
buttonElem.addEventListener("click", randomizePhraseBuffer);

function init() {
  // Filter based on decade if we have one specified
  if (typeof decade !== "undefined") {
    company = data.companies.filter(
      company => company.decade && company.decade.includes(decade)
    );
  }
  // if we ended up with too few results, let's revert to everything
  if (company.length === 0) {
    console.log("No decade results, using full dataset");
    company = data.companies;
  }
  adjective = data.adjectives;
  thing = data.things;

  randomizePhrase();
}

// Go!
init();
